import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Engine from "./Engine";
import {__, BORDER_RADIUS, formatAmount, isMobile, wait} from "../../../Helper";
import React from "react";
import storage from "../../../Storage";

function PlayersTable(props) {

    let {game} = props;
    const engine = Engine;

    function load() {
        wait(500).then(() => {
            engine.getPlayers();
        });
    }

    return (

        <AllBets engine={engine} t={props.t} game={game}/>
    )
}

class AllBets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
            winners: [],
            failed: [],
            isWinner: false
        };
    }

    onChange(event, players, winner) {
        this.setState({players: players, isWinner: winner})
    }

    onWinner(event, winners) {
        this.setState({winners: winners})
    }

    render() {
        const {players, winners} = this.state;
        return <Child engine={this.props.engine} onChange={this.onChange.bind(this, players)} onWinner={this.onWinner.bind(this, winners)}/>
    }
}

class Child extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            playersObject: [],
            winnersObject: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let {engine} = this.props;

            engine.trigger.on("finish_crash", data => this.syncFinishPlayer(data));
            engine.trigger.on("play_crash", data => this.syncPlayPlayer(data));
            engine.trigger.on("busted_crash", data => this.busted(data));
            engine.trigger.on("waiting_crash", data => this.isWaiting(data));
            engine.trigger.on("game_status", data => this.gameSync(data));
            engine.trigger.on("game_players", data => this.gameSync(data));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    syncPlayPlayer(data) {
        if (this._isMounted) {
            let add = this.state.playersObject.concat(data);
            this.setState({playersObject: add});
            this.props.onChange(this.state.playersObject)
        }
    }

    syncFinishPlayer(data) {
        if (this._isMounted) {
            let index = __.findIndex(this.state.playersObject, function (o) {
                return o.uid === data.uid;
            });
            this.state.playersObject.splice(index, 1);
            let add = this.state.winnersObject.concat(data);
            this.setState({winnersObject: add});
            this.props.onWinner(this.state.winnersObject)
        }
    }

    gameSync(data) {
        if (this._isMounted) {
            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });
            this.props.onChange(this.state.playersObject);

            sortByCashout(data.winners).forEach((item, i) => {
                this.state.winnersObject.push(item);
            });
            this.props.onWinner(this.state.winnersObject, true);
        }
    }

    busted(data) {
        if (this._isMounted) {
            //this.setState({playersObject: [], winnersObject: []});
            this.state.playersObject.shift();
            this.state.winnersObject.shift();

            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });

            this.props.onChange(this.state.playersObject);

            sortByCashout(data.winners).forEach((item, i) => {
                this.state.winnersObject.push(item);
            });

            this.props.onWinner(this.state.winnersObject, true);
        }
    }

    isWaiting(data) {
        if (this._isMounted) {
            this.setState({playersObject: [], winnersObject: []});

            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });
            this.props.onChange(this.state.playersObject);
        }
    }

    render() {
        return (
            <ShowUserTable
                engine={this.props.engine}
                players={this.state.playersObject}
                winners={this.state.winnersObject}
            />
        )
    }
}

class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({font: 'font-10'})
        }
    }

    render() {
        let playerRows = [];
        let winnerRows = [];
        let checkExists = [];

        if (this.props.players.length > 0) {
            this.props.players.forEach((currentPlayer, i) => {
                if (!checkExists.includes(currentPlayer.name)) {
                    checkExists.push(currentPlayer.name);
                    if (currentPlayer.session === 'crash')
                        playerRows.push(<UserRow engine={this.props.engine} currentPlayer={currentPlayer} key={i} isWinner={false} isFailed={false}/>);
                }
            });
        }

        if (this.props.winners.length > 0) {
            this.props.winners.forEach((currentPlayer, i) => {
                if (!checkExists.includes(currentPlayer.name)) {
                    checkExists.push(currentPlayer.name);
                    if (currentPlayer.session === 'crash')
                        winnerRows.push(<UserRow engine={this.props.engine} currentPlayer={currentPlayer} key={i} isWinner={true} isFailed={false}/>);
                }
            });
        }

        let playing_now = (playerRows.length + winnerRows.length);
        this.props.engine.trigger.emit('playing_now', playing_now);

        return (
            <>
                <Paper sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer sx={{maxHeight: 811}}>
                        <Table className={'custom-panel-dark'} stickyHeader size="small" aria-label="player list">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff'}}>User</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff'}} align="right">Stake [KES]</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff'}} align="left">&nbsp;&nbsp;&nbsp;&nbsp;@</TableCell>
                                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: '#ffffff'}} align="right">Won [KES]</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{textTransform: 'none'}}>
                                {playerRows}
                                {winnerRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </>
        );
    }
}

class ShowUserTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let size = 500;
        let players = sortByAmount(this.props.players);
        let winners = sortByCashout(this.props.winners);
        players.length = Math.min(players.length, size);
        winners.length = Math.min(winners.length, size);
        return (
            <UsersTable engine={this.props.engine} players={players} winners={winners}
            />
        );
    }
}


class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (this.props.currentPlayer.uid === undefined || this.props.currentPlayer.name === undefined)
            return null;

        let profit = (this.props.currentPlayer.won) ? this.props.currentPlayer.won : '-';

        let self = '', row_color = '#ffffff', cashout = '-';

        let bet = this.props.currentPlayer.amount;

        if (this.props.currentPlayer.token === storage.getKey('token')) {
            //self = 'bg-soft-warning';
            self = 'bg-panel-pc';
        }

        if (this.props.engine.gameStatus === 'busted' && profit === '-') {
            row_color = 'red';
        }

        if (profit !== '-') {
            row_color = '#04A784FF';
            profit = formatAmount(__.toNumber(profit) + __.toNumber(bet)); //temp - Total Won
            cashout = (this.props.currentPlayer.current / 100).toFixed(2) + 'x';
        }

        return (
            <>
                <TableRow key={this.props.currentPlayer.uid}>
                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: row_color}} component="th" scope="row">{this.props.currentPlayer.name}</TableCell>
                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: row_color}} align="right">{formatAmount(bet, 0)}</TableCell>
                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: row_color}} align="left">
                        <div style={{borderRadius: BORDER_RADIUS, backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}} className={'text-center'}>
                            {cashout}
                        </div>
                    </TableCell>
                    <TableCell className={'my-table-cell custom-panel-dark'} sx={{color: row_color}} align="right">{profit}&nbsp;&nbsp;&nbsp;</TableCell>
                </TableRow>
            </>
        )
    }
}

function sortByAmount(input) {
    function r(c) {
        return c.amount ? -c.amount : null;
    }

    return __.sortBy(input, r);
}

function sortByCashout(input) {
    function r(c) {
        return c.current ? -c.current : null;
    }

    return __.sortBy(input, r);
}

export default PlayersTable;