import Carousel from 'react-bootstrap/Carousel';
import {Event, TOP_CRASHERS, TOP_PLAYER} from "../../../Helper";
import React, {Component} from "react";
import CrasherHour from "./includes/CrasherHour";
import CrasherDay from "./includes/CrasherDay";

function showBannerPlayer() {
    Event.emit('show_banner_player');
}

function showBannerCrasher() {
    Event.emit('show_banner_crasher');
}

function BannerCarousal() {

    return (
        <Carousel>
            <Carousel.Item onClick={showBannerPlayer}>
                <img className={"d-block w-100"} style={{borderRadius: '6px 6px 0px 0px'}} src={TOP_PLAYER} alt="Player of the Hour"/>
                <div className={'d-block w-100 bg-image-custom text-white'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}><CrasherDay/></div>
            </Carousel.Item>
            <Carousel.Item onClick={showBannerCrasher}>
                <img style={{borderRadius: '6px 6px 0px 0px'}} className="d-block w-100" src={TOP_CRASHERS} alt="Crasher"/>
                <div className={'d-block w-100 bg-image-custom text-white'} style={{borderRadius: '0px 0px 6px 6px', fontSize: '0.8em', paddingLeft: '5px'}}><CrasherHour/></div>
            </Carousel.Item>
        </Carousel>
    );
}

export default BannerCarousal;