import React from 'react'
import {BORDER_RADIUS, LOGO_DESKTOP} from "../../../../Helper";
import {Link, NavLink} from "react-router-dom";
import {Divider, Grid} from "@mui/material";
import Button from "@mui/material/Button";

class Header extends React.Component {

    render() {
        return (
            <Grid className={'mb-2'} container spacing={3}>
                <Grid item xs={2}>
                    <NavLink style={{marginRight: '0.16rem'}} className={"navbar-brand"} to={"/"}>
                        <img src={LOGO_DESKTOP} style={{height: '37px'}}/>
                    </NavLink>
                </Grid>
                <Grid item xs>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <div className={'custom-card'}>
                            <Link className={'text-dark font-weight-bold'} style={{textDecoration: 'none'}} to={'/signup'}>Sign Up</Link>
                            <Divider className={"m-2"} color={"black"} orientation="vertical" flexItem/>
                            <Link className={'text-white font-weight-bold'} style={{textDecoration: 'none'}} to={'/login'}><Button variant="contained"
                             sx={{py: 0, color: 'white', borderRadius: BORDER_RADIUS, backgroundColor: '#05cc02'}} size={'small'}>Login</Button></Link>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Header;